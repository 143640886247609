<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "สร้างชำระเงิน",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      // tableData: tableData,

      title: "สร้างชำระเงิน",
      items: [
        {
          text: "หน้าหลัก",
        },
        {
          text: "สร้างชำระเงิน",
          active: true,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: "",
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      branchMaster: [],
      rowsRo: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      isHidden: false,
      payment: "",
      type: "",
      roId: "",
      serviceRo: [],
      // selectMode: "single",
      userBranchId: "",
      receipt: [
        {
          id: 1,
          receiptName: "ออกใบแจ้งหนี้ปกติ",
        },
        {
          id: 2,
          receiptName: "ออกใบแจ้งหนี้เคลม Warranty",
        },
      ],
      pay: [
        {
          id: 1,
          payName: "ชำระเงินสด",
        },
        {
          id: 2,
          payName: "เครดิต",
        },
      ],
      filter: {
        vin: "",
        licensePlate: "",
        branch: "",
      },
      userBranch: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "roCode",
          sortable: true,
          label: "รหัสใบซ่อม",
        },
        {
          key: "customerFullNameTh",
          sortable: true,
          label: "ชื่อ-สกุล",
        },
        {
          key: "licensePlate",
          sortable: true,
          label: "ป้ายทะเบียน",
        },
        {
          key: "vehicleBrandEn",
          sortable: true,
          label: "ยี่ห้อ",
        },
        {
          key: "vehicleModelEn",
          sortable: true,
          label: "รุ่น",
        },
        {
          key: "roDate",
          sortable: true,
          label: "วันที่สั่งซ่อม",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ยอดรวม",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "action",
          sortable: false,
          label: "ออกใบชำระเงิน",
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    this.getDataRo();
  },
  created() {
    // this.getData();
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/tax-invoice", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rows.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.isHidden = true;
        });
    },
    getDataRo: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/payment/repair-orders", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.filter.branch.branchId != null ? this.filter.branch.branchId : this.userBranchId,
          },
        })
        .then((response) => {
          this.rowsRo = response.data.data;
          this.rowsRo.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          this.isHidden = true;
        });
    },
    getDataRoShow: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/service/ro", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            svId: this.selected.svId,
          },
        })
        .then((response) => {
          this.rowsRo = response.data.data;
          this.rowsRo.forEach((item, index) => {
            this.rowsRo[index].roId = item.roId;
          });
          this.rowsRo.total = response.data.total;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
          this.isHidden = true;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getDataRo();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getDataRo();
    },
    onRowSelected(rows) {
      this.selected = rows[0];
    },
    handleSearch() {
      this.getDataRo();
    },
    resetModal() {
      this.rowsRo.forEach((item, index) => {
        this.rowsRo[index].roId = item.roId;
        this.rowsRo[index].roId = null;
      });
    },
    alertConfirm(roId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการออกใบชำระเงินหรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.confirmSubmitInvoice(roId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    confirmSubmitInvoice: function(roId) {
      this.roId = roId;
      useNetw
        .post("api/payment/store", {
          roId: this.roId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "detail-payment",
            params: { paymentId: response.data.paymentId },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ใบแจ้งหนี้:
                        <multiselect
                          v-model="type"
                          label="receiptName"
                          type="search"
                          :options="receipt"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        สาขา:
                        <multiselect
                          v-model="filter.branch"
                          label="nameTh"
                          type="search"
                          :options="branchMaster"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        เลขที่ใบแจ้งซ่อม:
                        <b-form-input
                          v-model="filter.vin"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        เลขใบแจ้งหนี้:
                        <b-form-input
                          v-model="filter.licensePlate"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ชื่อลูกค้า:
                        <b-form-input
                          v-model="filter.vin"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        เลขที่ใบกำกับภาษี:
                        <b-form-input
                          v-model="filter.licensePlate"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <b-button class="btn float-end" variant="info" type="submit" @click="handleSearch()">
                    <i class="uil-search-alt"></i>
                    ค้นหา
                  </b-button>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-sm-12 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      แสดงผล&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp;รายการ
                    </label>
                  </div>
                </div>
              </div>
              <div class="table-responsive mb-0">
                <b-table
                  :items="rowsRo"
                  :fields="fields"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  responsive="sm"
                >
                  <template #cell(index)="rows">
                    {{ rows.index + 1 }}
                  </template>
                  <template v-slot:cell(action)="rowsRo">
                    <ul class="list-inline mb-0">
                      <li
                        class="list-inline-item"
                        @click="alertConfirm(rowsRo.item.roId)"
                      >
                        <router-link
                          href="javascript:void(0);"
                          class="px-2 text-primary"
                          v-b-tooltip.hover
                          :to="{
                            params: { roId: rowsRo.item.roId },
                          }"
                          title="ออกใบชำระเงิน"
                        >
                          <i class="uil-file-check-alt font-size-18"></i>
                        </router-link>
                        <!-- <b-button
                                class="btn btn-sm"
                                variant="info"
                                type="submit"
                                :to="{
                                name: 'vehicleformdetail',
                                params: { vehicleId: rows.item.vehicleId },
                              }"
                              >
                                <i class="uil-search-alt"></i>
                                ออกใบกำกับภาษี
                              </b-button> -->
                      </li>
                      <!-- <li class="list-inline-item">
                            <a
                              href="javascript:void(0);"
                              class="px-2 text-danger"
                              v-b-tooltip.hover
                              title="Delete"
                              @click="alertDelete(rows.item.vehicleId)"
                            >
                              <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                          </li> -->
                    </ul>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.rows.total }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows.total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
